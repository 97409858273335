(function() {
  var app = angular.module("app");

  app.controller('ListController', ['$scope', '$rootScope', '$http', "functions", function($scope, $rootScope, $http, functions) {
    $rootScope.list = [];
    $scope.list_open = false;

    $http.get('/ajaxdata/get_list').
    success(function(data, status, headers, config) {
      $rootScope.list = data;
    }).
    error(function(data, status, headers, config) {
      error("Error Loading My List", data, true, null);
    });

    $scope.toggleList = function() {
      jQuery(".list-body").animate({
        height: "toggle"
      }, 500, function() {
        $scope.list_open = ($scope.list_open ? false : true);
        $scope.$apply();
      });
    };

    app.saveList = function() {
      $http({
        method: 'POST',
        url: '/organizations/list_save',
        data: jQuery.param({
          list: JSON.stringify($rootScope.list)
        }),
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).
      success(function(data, status, headers, config) {
        $rootScope.list = data;
        // $scope.$apply();
      }).
      error(function(data, status, headers, config) {
        error("Error Saving List", data, true, null);
      });
    };

    app.addList = function(organization) {
      organization.onlist = true;
      $rootScope.list.push(organization);
      app.saveList();

      if ($scope.list_open == false) {
        $scope.toggleList();
      }
    };

    app.removeList = function(organization) {
      var index = false;

      // Find the index for removal from the list.
      for (var x = 0; x < $rootScope.list.length; x++) {
        if (organization.organization_id == $rootScope.list[x].organization_id) {
          organization.onlist = false;
          index = x;
          break;
        }
      }

      // This will only apply when viewing an organizations list, not a single organization.
      try {
        // Find the actual organization object so we can unflag it.
        for (x = 0; x < $rootScope.organizations.length; x++) {
          if (organization.organization_id == $rootScope.organizations[x].organization_id) {
            $rootScope.organizations[x].onlist = false;
            break;
          }
        }
      } catch (e) {}

      if (index >= 0) {
        $rootScope.list.splice(index, 1);
        app.saveList();

        if ($scope.list_open == false) {
          $scope.toggleList();
        }
      }
    };

    $scope.addList = function(organization) {
      app.addList(organization);
    };
    $scope.removeList = function(organization) {
      app.removeList(organization);
    };
    $scope.saveList = function() {
      app.saveList();
    };
  }]);
})();
